import './App.css';
// import homeImgae from './HomeImage.jpg';
import homeImgae2 from './M&C-40.JPEG';

function App() {
  return (
     <div style={{
      backgroundImage: `url(${homeImgae2})`,
      backgroundSize: "cover",
      backgroundPosition: "center",
      height: "100vh",
      display: "flex",
      alignItems: "center",
      filter: "blur(0px)",
      backdropFilter: "blur(1px)",
      justifyContent: "center"
    }}>
      <button 
        class="glow-on-hover"
        onClick={() => window.open('https://docs.google.com/forms/d/e/1FAIpQLSfKtbIfiu_YRXCHqopdL_2al8qNiPEfMYHoEhEyqqGizH56cw/viewform')}
      >
        Lets plan
      </button>
    </div>
  );
}

export default App;